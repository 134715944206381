<template>
  <v-form>
    <v-alert
      v-if="alert"
      border="left"
      colored-border
      color="success"
      elevation="2"
      class="alert-edit"
      type="success"
      transition="fade-transition">
      Contact successfully added! Re-routing...
    </v-alert>
    <v-overlay :value="overlay">
     <v-progress-circular
       indeterminate
       size="64"
     ></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col
        cols="12"
      >
         <v-autocomplete
          v-model="businessSelected"
          :items="businessItems"
          :loading="isBusinessLoading"
          :search-input.sync="businessSearch"
          hide-no-data
          hide-details="auto"
          item-text="name"
          item-value="id"
          label="Select business"
          placeholder="Start typing to Search"
          prepend-icon="mdi-domain"
          return-object
          @change="selectBusiness"
          :error="(businessSelected == '' || !businessSelected) && showError"
          :rules="[v => !!v || 'Field is required']"
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="12"
      >
       <v-select
         v-model="contact.referral_type"
         :items="typeitems"
         item-value="id"
         label="Select referral type"
         outlined
         prepend-icon="mdi-file-document"
         hide-details="auto"
       >
      </v-select>
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <v-checkbox
          v-model="contact.intake_completed"
          outlined
          label="Intake Completed?"
          hide-details="auto"
          :class="{ 'ml-6': $vuetify.breakpoint.mdAndUp }"
        ></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <v-checkbox
          v-model="contact.connect"
          outlined
          label="Connect?"
          hide-details="auto"
        ></v-checkbox>
      </v-col>
      <v-col
        v-if="contact.referral_type == 2"
        cols="12"
      >
       <v-select
         v-model="contact.program_name"
         :items="programItems"
         item-value="id"
         item-text="name"
         label="Select program"
         outlined
         prepend-icon="mdi-file-document"
         hide-details="auto"
       />
    </v-col>
      <v-col
        cols="12"
        sm="6"
        >
          <v-menu
            ref="menu"
            v-model="date_menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
              v-model="formatted_date"
              label="Date"
              prepend-icon="mdi-calendar"
              hint="MM/DD/YYYY format"
              persistent-hint
              readonly
              v-bind="attrs"
              v-on="on"
              ></v-text-field>
            </template>
          <v-date-picker
            v-model="contact.date_created"
            :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
            min="1950-01-01"
            @change="datePickerSave"
          ></v-date-picker>
          </v-menu>
        </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          v-model="contact.status"
          :items="statusItems"
          item-value="id"
          item-text="name"
          label="Status"
          outlined
          prepend-icon="mdi-list-status"
          hide-details="auto"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
       <v-select
         v-model="contact.assigned_to"
         :items="assignItems"
         item-value="id"
         item-text="name"
         label="Assign to"
         outlined
         prepend-icon="mdi-account"
         hide-details="auto"
       />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
       <v-select
         v-model="contact.membership_type"
         :items="membershipItems"
         item-value="id"
         item-text="name"
         label="Select membership"
         outlined
         prepend-icon="mdi-card-account-details"
         hide-details="auto"
       />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
       <v-select
         v-model="contact.contact_source"
         :items="sourceItems"
         item-value="id"
         item-text="name"
         label="Select contact source"
         outlined
         prepend-icon="mdi-account-group"
         hide-details="auto"
       />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="contact.referral_source"
          outlined
          label="Referral source"
          prepend-icon="mdi-account-group"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        v-if="this.$store.getters.user.is_superuser"
      >
        <v-autocomplete
         v-model="staffSelected"
         :items="staffItems"
         :loading="isStaffLoading"
         :search-input.sync="staffSearch"
         hide-no-data
         hide-details="auto"
         item-text="name"
         item-value="id"
         label="Select staff"
         placeholder="Start typing to Search"
         prepend-icon="mdi-face-agent"
         return-object
         @change="selectStaff"
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="12"
      >
        <v-select
          v-model="contact.reason_for_inquiry"
          :items="reasonItems"
          item-value="id"
          item-text="name"
          label="Reason for inquiry"
          outlined
          prepend-icon="mdi-help"
          hide-details="auto"
        />
      </v-col>
      <v-col
        cols="12"
      >
        <v-textarea
          v-model="contact.notes"
          outlined
          label="Notes"
          prepend-icon="mdi-pen"
          hide-details="auto"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-btn
      @click="addContact()"
      x-large
      color="primary"
      bottom
      right
      absolute
      :class="{ 'v-btn--mobile absolute' : $vuetify.breakpoint.xsOnly }"
    >
      Add Contact
    </v-btn>
  </v-form>
</template>
<script>
  import { mapActions } from 'vuex';
  //import moment from 'moment'

  export default {
    name: 'AddContact',
    data() {
      return {
        contact: {
          date_created: (new Date(Date.now() + (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          business: '',
          contact_source: '',
          outreach_specialist: '',
          referralto: '',
          referral_source: '',
          reason_for_inquiry: '',
          notes: '',
          referral_type: null,
          program_name: '',
          intake_completed: false,
          connect: false,
          status: null,
          membership_type: null,
          assigned_to: null
        },
        typeitems: [
          { id: 0, text:'------'},
          { id: 1, text:'Internal - Technical Assitance'},
          { id: 2, text:'External'}
        ],
        statusItems: [
          { id:0, name:'Close' },
          { id:1, name:'Active' }
        ],
        assignItems: [
          { id: 0, name: 'Jim Nicholas' },
          { id: 1, name: 'Scott Arnold' },
          { id: 2, name: 'Tracey Joseph' },
          { id: 3, name: 'Latrice Allen' }
        ],
        membershipItems:[],
        programItems: [],
        sourceItems: [],
        reasonItems: [],
        businessSelected: null,
        staffSelected: null,
        overlay: false,
        showError: false,
        businessEntries: [],
        isBusinessLoading: false,
        businessSearch: null,
        staffEntries: [],
        isStaffLoading: false,
        staffSearch: null,
        count: 0,
        formatted_date: ((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
        date_menu: false,
        alert: false,

      }
    },
    computed: {
      fields () {
        if (!this.model) return []

        return Object.keys(this.model).map(key => {
          return {
            key,
            value: this.model[key] || 'n/a',
          }
        })
      },
      businessItems () {
        return this.businessEntries.map(entry => {
          const name = entry.name
          return Object.assign({}, entry, { name })
        })
      },
      staffItems() {
        return this.staffEntries.map(entry => {
          const name = `${entry.first_name} ${entry.last_name}`
          return Object.assign({}, entry, { name })
        })
      }
    },
    created() {
      this.getBusinesses({ ordering: 'name', search: '', limit: 0, offset: 0 })
      .then((response) => {
        this.businessEntries = response.data.results
      });

      this.getPrograms()
      .then((response) => {
        this.programItems = response.data.results
      });

      this.getReasons()
      .then((response) => {
        this.reasonItems = response.data.results
      });

      this.getContactSource()
      .then((response) => {
        this.sourceItems = response.data.results
      });

      this.getMembership()
      .then((response) => {
        this.membershipItems = response.data.results
      });

      if(this.$store.getters.user.is_superuser){
        this.getStaff({ id: this.$store.getters.user.id })
        .then(staffresponse => {
          this.staffEntries = [staffresponse.data]
          this.staffSelected = this.staffEntries.find((entry) => entry.id == this.$store.getters.user.id)
          this.contact.outreach_specialist = this.staffSelected.id
        })
        .catch(err => { console.log(err) })
      } else {
        console.log('loggedIn user', this.$store.getters.user)
        this.contact.outreach_specialist = this.$store.getters.user.id
      }

      this.formatted_date = this.formatDate(this.formatted_date);
    },
    methods: {
      ...mapActions(['getAllStaff', 'getStaff', 'searchStaff', 'getBusinesses', 'createContact', 'searchBusinesses', 'getPrograms', 'getReasons', 'getContactSource', 'getMembership']),
      addContact() {
        this.overlay = true;
        const contact_record = Object.assign({}, this.contact);
        const dateToday = new Date(Date.now() + new Date().getTimezoneOffset() * 60000)
        const timeCreated = `${dateToday.getHours()}:${dateToday.getMinutes()}:00`
        const dateWithTime = `${contact_record.date_created}T${timeCreated}Z`
        contact_record.date_created = dateWithTime

        this.createContact(contact_record)
        .then(() => {
          this.overlay = false;
          window.scrollTo({ top: 0, behavior: 'smooth' });
          this.alert = true;
          setTimeout(() => {
            this.$router.push({ name: 'contactslist' })
          }, 3000)
        }, () => {
          this.showError = true
          this.overlay = false;
        });
      },
      selectBusiness(val) {
        this.contact.business = val.id
        this.contact.business_name = val.name
      },
      selectStaff(val) {
        this.contact.outreach_specialist = val.id
      },
      datePickerSave (date) {
        this.$refs.menu.save(date)
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      }
    },
    watch: {
      businessSearch (val) {
        console.log('val', val)

        this.isBusinessLoading = true

        // Lazily load input items
        this.getBusinesses({search:val})
          .then(res => {
            const { count, results } = res.data
            this.count = count
            this.businessEntries = results
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isBusinessLoading = false))
      },
      staffSearch (newVal) {
        this.isStaffLoading = true
        this.searchStaff({name:newVal, limit: 10, offset:0})
        .then(res => {
          this.staffEntries = res.data.results
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (this.isStaffLoading = false))
      },
      contact: {
        handler(val){
          this.formatted_date = this.formatDate(val.date_created);
        },
        deep: true
      }
    }
  }
</script>
<style lang="scss" scoped>

</style>
